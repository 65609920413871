.item-list{
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
}
.item-list li{
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
    padding : 15spx;
    color: black;
    display : flex;
    justify-content: space-between;
    margin : 10px;
    position: relative;
}
