@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@1,100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.expense{
    border-right: 5px solid red;

}

.income{
    border-right:  5px solid #2ccc71;
}
.item-list{
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
}
.item-list li{
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
    padding : 15spx;
    color: black;
    display : flex;
    justify-content: space-between;
    margin : 10px;
    position: relative;
}

*{
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif;
}


body{
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
}
.container{
  margin: 30px auto;
  width: 350px;
  
}
.horizontal-menu{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  text-align: center;
}
.horizontal-menu li{
  display:  inline-block;
}
.horizontal-menu a{
  color: white;
  text-decoration: none;
  padding: 16px;
  display:  block;
}
label{
    display: inline-block;
    margin: 10px;
}
input{
    border: 1px solid #dedede;
    border-radius: 2px;
    display: block;
    font-size: 16px;
    padding: 10px;
    width: 100%;
}
.btn{
    background-color: purple;
    color: #fff;
    cursor: pointer;
    display: block;
    margin: 10px 0 30px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}

input:focus
{
    outline: none;
}
.report-container{
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 15px;
    margin: 15px 0;
}

.report-container>div{
    flex: 1 1;
    text-align: center;
}
.report-container>div:first-of-type{
    border-right: 1px solid #dedede;
}
.report {
    font-size: 20px;
    letter-spacing: 1px;
    margin:  5px 0;
}
.report.plus{
    color : #2ecc71;
}
.report.minus{
    color: red;
}
