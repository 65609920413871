label{
    display: inline-block;
    margin: 10px;
}
input{
    border: 1px solid #dedede;
    border-radius: 2px;
    display: block;
    font-size: 16px;
    padding: 10px;
    width: 100%;
}
.btn{
    background-color: purple;
    color: #fff;
    cursor: pointer;
    display: block;
    margin: 10px 0 30px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}

input:focus
{
    outline: none;
}